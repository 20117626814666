<template>
  <div>
    <ServerError v-if="ServerError" />

    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="updateStatusDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '50vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '30vw'
          : '20vw'
      "
    >
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12 text-left>
            <span class="bookingHeading"> Update Status </span>
          </v-flex>
          <v-flex xs12 pt-3>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-select
                  :items="statusArray"
                  label="status"
                  class="tableValue1"
                  dense
                  v-model="deliveryStatus"
                  item-value="value"
                  item-text="value"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 v-if="deliveryStatus == 'Shipped'">
                <v-text-field
                  v-model="courierservice"
                  class="tableValue1"
                  outlined
                  label="Courier Service"
                  color="#68d389"
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="trackingid"
                  class="tableValue1"
                  outlined
                  label="Tracking Id"
                  color="#68d389"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-btn
              small
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="updateStatusDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6>
            <v-btn
              small
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="validateInput"
              class="itemValue"
            >
              Update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-layout v-for="(item, i) in purchaseData" :key="i" wrap justify-start>
      <v-flex xs12 md10 pt-4>
        <v-card outlined>
          <v-layout pa-3 wrap justify-center>
            <v-flex xs6 text-left pl-2 align-self-center>
              <span class="itemCaption" style="font-size: 18px">
                {{ item.product.name }}
              </span>
              <v-btn
                outlined
                :ripple="false"
                depressed
                :disabled="
                  item.deliverystatus == 'Delivered' ||
                  item.deliverystatus == 'Cancelled'
                    ? true
                    : false
                "
                small
                @click="
                  (updateStatusDialog = true),
                    (deliveryStatus = item.deliverystatus),
                    (deliveryItem = item),
                    (courierservice = item.courierservice),
                    (trackingid = item.trackingid)
                "
              >
                <span class="itemCaption" style="font-size: 13px">
                  Update Status
                </span>
              </v-btn>
            </v-flex>
            <v-flex xs6 align-self-center>
              <v-layout wrap justify-end>
                <v-flex xs12 sm6 md6 lg6 xl6>
                  <a
                    target="_blank"
                    :href="
                      'https://api.mudumalaitigerreserve.com/purchase/getInvoicePdf?id=' +
                      item._id
                    "
                    class="downlink"
                  >
                    <span
                      style="
                        font-size: 16px;
                        font-family: poppinssemibold;
                        color: #68d389;
                      "
                    >
                      Download Invoice</span
                    >
                  </a>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6 xl6>
                  <a
                    target="_blank"
                    :href="
                      'https://api.mudumalaitigerreserve.com/purchase/getAddressPdf?id=' +
                      item._id
                    "
                    class="downlink"
                  >
                    <span
                      style="
                        font-size: 16px;
                        font-family: poppinssemibold;
                        color: #68d389;
                      "
                    >
                      Download Address</span
                    >
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2 align-self-center>
                  <v-img
                    v-if="item.product.images.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.product.images[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                    src="../../../../assets/images/home.jpg"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pl-4>
                  <v-layout wrap justify-start> </v-layout>
                  <v-layout wrap pt-2>
                    <v-flex md6 text-left>
                      <span class="slotText"> Purchased Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.payment.init_date) }}
                      </span>
                      <v-layout wrap>
                        <v-flex md12 text-left>
                          <span class="slotText">Purchaser Details</span>
                        </v-flex>
                        <v-flex md12 text-left>
                          <span class="tableValue1">{{ item.payment.name }}</span>
                          <br />
                          <span class="tableValue1">{{ item.payment.email }}</span>
                          <br />
                        </v-flex>
                      </v-layout>

                      <!-- <v-layout wrap>
                        <v-flex md12 text-left>
                          <span class="slotText">Purchased Data</span>
                        </v-flex>
                        <v-flex md12 text-left>
                          <span class="tableValue1">Kurunthen</span>
                          <br />
                          <span class="tableValue1"
                            >150gm x {{ item.quantity }}</span
                          >
                          <br />
                        </v-flex>
                      </v-layout> -->
                    </v-flex>

                    <v-flex md6>
                      <v-layout wrap>
                        <v-flex md12 text-left>
                          <span class="slotText">Purchased Address</span>
                        </v-flex>
                        <v-flex md12 text-left v-if="item.payment.deliveryaddress">
                          <span class="tableValue1"
                            >{{ item.payment.deliveryaddress.firstname }}
                            {{ item.payment.deliveryaddress.lastname }}</span
                          >
                          <br />
                          <span class="tableValue1">{{
                            item.payment.deliveryaddress.email
                          }}</span>
                          <br />
                          <span class="tableValue1">{{
                            item.payment.deliveryaddress.phone
                          }}</span>
                          <br />
                          <span class="tableValue1">{{
                            item.payment.deliveryaddress.streetAddress
                          }}</span>
                          <br />
                          <span class="tableValue1"
                            >{{ item.payment.deliveryaddress.city }}
                            {{ item.payment.deliveryaddress.district }}
                            {{ item.payment.deliveryaddress.state }}</span
                          >
                          <br />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap v-if="item.trackingid" pa-3>
                <v-flex md4 text-left>
                  <span class="slotText">Tracking Id</span>
                </v-flex>
                <v-flex md8 text-right>
                  <span class="tableValue1">{{ item.trackingid }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap v-if="item.courierservice">
                    <v-flex md4 text-left>
                      <span class="slotText">Courier Service</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="tableValue1">{{ item.courierservice }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>
              <v-layout wrap pa-3>
                <!-- <v-flex md12 text-left>
                  <span class="slotText">Purchased Data</span>
                </v-flex> -->
                <v-flex md4 text-left v-if="item.unitname">
                  <span class="slotText1">Type</span>
                </v-flex>
                <v-flex md8 text-right v-if="item.unitname">
                  <span class="tableValue1">{{ item.unitname }}</span>
                </v-flex>
                <v-flex md4 text-left>
                  <span class="slotText1">Quantity</span>
                </v-flex>
                <v-flex md8 text-right>
                  <span class="tableValue1"
                    >{{ item.sizename }} x {{ item.quantity }}</span
                  >
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-3>
                <v-flex md12 text-left>
                  <span class="slotText">Payment Details</span>
                </v-flex>
                <v-flex md12 text-left>
                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="slotText1">Amount</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="tableValue1">{{ item.productPrice }} INR</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="slotText1">Delivery Charge</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="tableValue1"
                        >{{ Math.round(item.deliveryCharge) }} INR</span
                      >
                    </v-flex>
                  </v-layout>

                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="slotText1">Tax</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="tableValue1">{{ item.tax }} INR</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="slotText1">Payment Gateway Charge</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="tableValue1"
                        >{{ Math.round(item.paymentgatewayCharge) }} INR</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-3>
                <v-flex md4 text-left>
                  <span class="slotText1">GRAND TOTAL</span>
                </v-flex>
                <v-flex md8 text-right>
                  <span class="tableValue1">{{ Math.round(item.total) }} INR</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 v-if="purchaseData.length == 0" text-center>
        <span style="fonr-family: poppinsbold; font-size: 18px">
          {{ status }} List is Empty</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";

export default {
  props: ["status", "storage"],
  components: {
    ImageLoader,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      product: [],
      msg: null,
      ServerError: false,
      updateStatusDialog: false,
      deliveryStatus: null,
      deliveryItem: {},
      trackingid: null,
      courierservice: null,
      statusArray: [
        { title: "PENDING", value: "Pending" },
        { title: "SHIPPED", value: "Shipped" },
        { title: "DELIVERED", value: "Delivered" },
        { title: "CANCELLED", value: "Cancelled" },

        // { title: "COTTAGES", value: "cottage" },
      ],
    };
  },

  computed: {
    purchaseData() {
      if (this.storage.length > 0) return this.storage;
      else return [];
    },
  },

  // watch: {
  //   purchaseData: {
  //     handler() {
  //       for (var i = 0; i < this.purchaseData.length; i++) {
  //         if (this.purchaseData[i].deliverystatus == "Shipped") {
  //           this.courierservice = this.purchaseData[i].courierservice;
  //           this.trackingid = this.purchaseData[i].trackingid;
  //         }
  //       }
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    validateInput() {
      if (this.deliveryStatus == "Shipped") {
        if (!this.courierservice) {
          this.msg = "Please add Courier Service";
          this.showSnackBar = true;
        } else if (!this.trackingid) {
          this.msg = "Please add Tracking Id";
          this.showSnackBar = true;
        } else this.updateStatus();
      } else this.updateStatus();
    },
    updateStatus() {
      var data = {};
      data["status"] = this.deliveryStatus;
      data["id"] = this.deliveryItem._id;
      if (this.deliveryStatus == "Shipped") {
        data["courierservice"] = this.courierservice;
        data["trackingid"] = this.trackingid;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/deliverystatus",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.showSnackBar = true;
            this.appLoading = false;
            this.msg = response.data.msg;
            this.updateStatusDialog = false;
            this.$emit("stepper", {
              getData: true,
              deliveryStatus: this.deliveryStatus,
              status: response.data.status,
            });
          } else {
            this.appLoading = false;

            this.$emit("stepper", {
              status: response.data.status,
              msg: response.data.msg,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>